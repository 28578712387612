"use client";
import React, { createContext, useContext, useState, useEffect } from "react";
import { parseJwt } from "@utils/parseJwt";
import { userLogout } from "@utils/userLogout";
import { SignInOTPResponse } from "@queries/creator/sendEmailOTP";
import { useQueryClient } from "@tanstack/react-query";
import { deleteItem, getItem, setItem } from "@utils/localStorage";
import { getUser as getDistributor } from "@queries/master_distributors/getUser";
import { getUser as getCreator } from "@queries/creator/getUser";
import { Roles } from "@enums/Roles";
import { useSearchParams } from "next/navigation";

interface AuthContextProps {
  user: User | null;
  loading: boolean;
  roles: Roles[] | null;
  role: Roles | null;
  logout: () => Promise<void>;
  setSession: (data: SignInOTPResponse["data"]) => void;
  setActiveRole: (role: Roles) => void;
  hasRole: (role: Roles) => boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = useQueryClient();

  const params = useSearchParams();
  const accessTokenParam = params.get("access_token");
  const refreshTokenParam = params.get("refresh_token");

  useEffect(() => {
    if (accessTokenParam && refreshTokenParam) {
      deleteItem("access_token");
      deleteItem("refresh_token");
      setItem("access_token", accessTokenParam);
      setItem("refresh_token", refreshTokenParam);
    }
  }, [accessTokenParam, refreshTokenParam]);

  const [user, setUser] = useState<User | null>(null);
  const [roles, setRoles] = useState<Roles[] | null>(null);
  const [role, setRole] = useState<Roles | null>(null);
  const [loading, setLoading] = useState(true);

  const setSession = (data: SignInOTPResponse["data"]) => {
    const { access_token, email, first_name, last_name } = data;
    try {
      const jwt = parseJwt<JWTSchema>(access_token);

      if (!jwt) {
        deleteItem("access_token");
        return;
      }

      setUser({ email, first_name, last_name });
      setRoles(jwt.roles);
      setLoading(false);
      setRole(jwt.roles.at(0)!);
    } catch (error) {
      userLogout();
    }
  };

  const logout = async () => {
    queryClient.invalidateQueries();

    setUser(null);
    setRoles(null);
    setRole(null);

    userLogout();
  };

  const hasRole = (role: Roles) => {
    return roles?.includes(role) || false;
  };

  const setActiveRole = (role: Roles) => {
    setItem("role", role);
    setRole(role);
  };

  useEffect(() => {
    const access_token = getItem("access_token");
    const role = getItem("role") as Roles;
    queryClient.invalidateQueries();

    if (access_token) {
      try {
        const jwt = parseJwt<JWTSchema>(access_token);

        if (!jwt) {
          deleteItem("access_token");
          return;
        }

        const isCreator =
          role && jwt.roles.includes(role)
            ? role === Roles["creator.admin"]
            : jwt.roles.includes(Roles["creator.admin"]);

        const getUser = isCreator ? getCreator : getDistributor;

        getUser()
          .then((res) => {
            if (!res.data.has_password) {
              // making the user log in again to set up the password
              // deleteItem("access_token");
              // deleteItem("refresh_token");
            }
            setUser(res.data);
            setRoles(jwt.roles);
            setLoading(false);
            setRole(jwt.roles.at(0)!);
          })
          .catch((err) => {
            console.log("Error occurred during authentication: ", err);
          });
      } catch (error) {
        userLogout();
      }
    } else {
      setLoading(false);
    }
  }, [role, queryClient]);

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        roles,
        role,
        setSession,
        setActiveRole,
        logout,
        hasRole,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};
